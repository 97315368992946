<template>
    <div class="modal-vertical-buttons">
        <div class="modal-vertical-top">
            <div class="modal-vertical-title">{{ title }}</div>
            <img
                v-if="showCloseButton"
                class="modal-vertical-close"
                @click="onModalClose"
                src="@/assets/images/icons/close.png"
                width="24px"
                height="24px"
            />
        </div>
        <div class="modal-vertical-buttons-list-items" v-if="options.listItem">
            <div v-for="(item, idx) in editedListItem" :key="idx">
                {{ $translate(item.toUpperCase()) }}
            </div>
            <div v-if="options.listItem.length !== editedListItem.length">...</div>
        </div>
        <div v-if="body" class="modal-vertical-body" v-html="body" />
        <div class="modal-vertical-buttons-wrapper">
            <button
                :key="idx"
                @click="onClickButton(btn)"
                v-for="(btn, idx) in buttons"
                class="modal-vertical-button"
                :style="btnStyle(btn)"
            >
                {{ btn.label }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalVerticalButtons',
    props: ['options'],
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
        buttons() {
            return (this.options || {}).buttons || []
        },
        showCloseButton() {
            return (this.options || {}).showCloseButton || null
        },
        editedListItem() {
            if (!this.options.listItem) return []
            return ((this.options || {}).listItem || []).slice(0, 4) || []
        },
        btnStyle() {
            return btn => {
                if (btn.style) {
                    return {
                        height: this.options.height ? this.options.height : '40px',
                        'background-color': btn.style.bgColor || 'none',
                        color: btn.style.color || '#111',
                        border: btn.style.border || 'border: 1px solid #b9bdc1',
                    }
                } else {
                    return {
                        height: this.options.height ? this.options.height : '40px',
                        'background-color': this.options.bgColor || 'none',
                        color: this.options.bgColor ? '#FFFFFF' : '#111',
                        border: this.options.bgColor ? 'none' : 'border: 1px solid #b9bdc1',
                    }
                }
            }
        },
    },
    methods: {
        onModalClose() {
            this.$emit('close')
        },
        onClickButton(btn) {
            if (this.options.onClick) {
                this.options.onClick()
            }
            this.$emit('close', this.buttons.indexOf(btn))
        },
    },
}
</script>
<style scoped lang="scss">
.modal-vertical-buttons {
    margin: 0 24px;
    width: calc(100vw - 48px);
    width: -webkit-calc(100vw-48px);
    width: -moz-calc(100vw-48px);
    color: #111111;

    .modal-vertical-top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .modal-vertical-title {
            font-size: 16px;
            line-height: 26px;
            align-self: center;
        }
        .modal-vertical-close {
            align-self: flex-start;
        }
    }
    .modal-vertical-buttons-list-items {
        font-size: 14px;
        line-height: 24px;
        margin-top: 16px;
        background-color: #f4fcff;
        padding: 8px 16px;
    }
    .modal-vertical-body {
        margin-top: 16px;
    }

    .modal-vertical-buttons-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 17px;
        .modal-vertical-button {
            width: 100%;
            margin: 0px;
            border: 1px solid;
            font-size: 14px;
            line-height: 24px;
            font-weight: normal;

            border-radius: 8px;
        }
        .modal-vertical-button:not(:last-child) {
            margin-bottom: 4px;
        }
        .modal-vertical-button:not(:first-child) {
            margin-top: 4px;
        }
    }
}
</style>
